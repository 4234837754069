// import React, { useRef } from 'react'
// import { useGLTF, useAnimations } from '@react-three/drei'

// export function LilGirl(props) {
//   const group = useRef()
//   const { nodes, materials, animations } = useGLTF('/lilgirl.glb')
//   const { actions } = useAnimations(animations, group)
//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
//           <primitive object={nodes.mixamorigHips} />
//           <skinnedMesh name="high_poly" geometry={nodes.high_poly.geometry} material={materials['default']} skeleton={nodes.high_poly.skeleton} />
//         </group>
//       </group>
//     </group>
//   )
// }

// useGLTF.preload('/lilgirl.glb')


import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function Girl(props) {
  const { nodes, materials } = useGLTF('/lilgirl.glb')
  for (const material in materials) {
    materials[material].metalness = -2
    materials[material].roughness = 1
  }

  return (
    <group name="Root_Scene">
      <group name="RootNode">
        <group name="CharacterArmature" rotation={[Math.PI / 2, 0, 0]} scale={0.024} position={[0, -0.85, 0]}>
          {/* <primitive object={nodes.Root} /> */}
          <primitive object={nodes.mixamorigHips} />
        </group>
        <group name="girl" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <skinnedMesh
                name="high_poly"
                geometry={nodes.high_poly.geometry}
                material={materials['default']}
                skeleton={nodes.high_poly.skeleton}
                receiveShadow
                castShadow
            />
        </group>
          
      </group>
    </group>
  )
}

useGLTF.preload('/lilgirl.glb')

